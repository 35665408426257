<template>
  <section v-if="loading">
    Loading...
  </section>

  <section v-else>
    <header class="flex flex-wrap items-center mb-6">
      <h2 class="pr-3 font-semibold text-gray-600">
        Notifications
      </h2>
    </header>

    <div class="rv-default-data-list data-list-container">
      <vs-table
        id="rj-default-table"
        ref="table"
        :data="notifications"
        :max-items="itemsPerPage"
        :no-data-text="'All caught up!'"
        search
        pagination
      >
        <template slot="header">
          <div class="hidden sm:flex items-center flex-grow justify-between">
            <div class="flex items-center data-list-btn-container space-x-4" />

            <vs-dropdown
              v-if="notifications.length"
              vs-trigger-click
              class="cursor-pointer mr-4 items-per-page-handler"
            >
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                  {{
                    notifications.length - currentPage * itemsPerPage > 0
                      ? currentPage * itemsPerPage
                      : notifications.length
                  }}
                  of {{ queriedItems }}
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  svg-classes="h-4 w-4"
                />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 50">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 100">
                  <span>100</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </template>

        <template slot="thead">
          <vs-th>Date</vs-th>
          <vs-th>Type</vs-th>
          <vs-th />
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr
              v-for="notification in data"
              :key="notification.id"
              :data="notification"
              :class="{ 'font-bold': !notification.viewedAt, 'text-danger': ['SMS Usage'].includes(notification.type) }"
            >
              <vs-td class="whitespace-nowrap">
                <p>
                  <button
                    style="font-weight: inherit;"
                    @click="handleSystemNotification(notification)"
                  >
                    {{
                      format(new Date(notification.createdAt), 'MMM. d, yyyy')
                    }}
                  </button>
                </p>
              </vs-td>
              <vs-td class="whitespace-nowrap">
                <button
                  style="font-weight: inherit;"
                  @click="handleSystemNotification(notification)"
                >
                  {{ notification.type || '' }}
                </button>
              </vs-td>
              <vs-td>
                <p>
                  <button
                    style="font-weight: inherit;"
                    @click="handleSystemNotification(notification)"
                  >
                    {{ notification.text }}
                  </button>
                </p>
              </vs-td>
              <vs-td class="whitespace-nowrap">
                <div class="flex">
                  <vs-button
                    v-if="!notification.viewedAt"
                    icon-pack="feather"
                    icon="icon-check"
                    type="flat"
                    class="inline-block"
                    title="Mark as Read"
                    :color="themeColors.rjPurple"
                    @click.stop="markAsRead(notification)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </section>
</template>

<script>
import { format } from 'date-fns';
import { colors as themeColors } from '@/../themeConfig';
import filters from '@/views/common/notifications/filters';
import { formatToTimeZone } from 'date-fns-timezone';

export default {
  name: 'RjNotifications',

  components: {
  },

  data() {
    return {
      itemsPerPage: 20,
      isMounted: false,
      themeColors,
      selected: {},
      filters: {},
      filtering: false,
      notifications: [],
      loading: true,
      hasFilters: false,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    currentPage() {
      return this.isMounted && this.$refs.table ? this.$refs.table.currentx : 1;
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.notifications.length;
    },

    sms() {
      return this.$store.getters['companies/sms'];
    },
  },

  async mounted() {
    this.isMounted = true;

    await this.fetchNotifications();
    this.loading = false;
  },

  methods: {
    format,

    async fetchNotifications() {
      const {
        startDate,
        endDate,
      } = this.filters;

      const filter = {
        where: {
          active: true,
        },
        order: 'createdAt DESC',
        limit: 500,
      };

      if (this.auth.user.companyId) {
        filter.where.companyId = this.auth.user.companyId;
      } else if (this.auth.user.partnerId) {
        filter.where.partnerId = this.auth.user.partnerId;
      }

      if (startDate && endDate) {
        filter.where.createdAt = {
          between: [startDate.toISOString(), endDate.toISOString()],
        };
      } else if (startDate) {
        filter.where.createdAt = {
          gte: startDate.toISOString(),
        };
      } else if (endDate) {
        filter.where.createdAt = {
          lte: endDate.toISOString(),
        };
      }

      this.notifications = await this.$store.dispatch(
        'notifications/getNotifications',
        filter,
      );
    },

    async markAsRead(notification) {
      try {
        if (notification.viewedAt) {
          return;
        }
        const viewedAt = new Date().toISOString();
        const payload = {
          id: notification.id,
          viewedAt,
        };

        await this.$store.dispatch('notifications/updateNotification', payload);
        this.$store.dispatch('notifications/getTop5Notifications');

        const index = this.notifications.findIndex((n) => n.id === notification.id);
        if (index < 0) {
          return;
        }
        // this.$store.commit(MARK_AS_READ, notification);
        this.notifications[index].viewedAt = viewedAt;
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    handleSystemNotification(notification) {
      if (notification.type === 'SMS Usage') {
        this.triggerSmsDialog();
        this.markAsRead(notification);
      }
    },

    triggerSmsDialog() {
      if (this.sms.max > this.sms.used) {
        return;
      }

      const partner = this.$store.getters['partners/partner'];
      const renewalDate = formatToTimeZone(new Date(this.sms.renewalDate), 'Do', { timeZone: 'UTC' });
      const dialog = (attributes) => this.$vs.dialog(attributes);

      const upgradeIntent = {
        type: 'alert',
        color: 'primary',
        title: 'Limit Reached',
        text: `Sounds good! We just sent ${partner.name} a notification about your interest in more SMS credits. They should reach out to you soon with more information.`,
        acceptText: 'OK',
      };

      const limitReached = {
        type: 'alert',
        color: 'primary',
        title: 'Limit Reached',
        text: `You have sent the maximum number of SMS messages for your account. Your monthly allotment of ${this.sms.max} SMS credits will refresh on the next ${renewalDate} of the month. Until then, you may only send email surveys. Or, would you like to request more information from ${partner.name} to add another 500-pack of SMS credits?`,
        acceptText: 'Yes',
        accept: () => {
          dialog(upgradeIntent);
          this.$store.dispatch('companies/sendAdditionalSmsRequest', this.company.id);
        },
      };

      dialog(limitReached);
    },

    applyFilters(filters) {
      this.filters = filters;
      this.hasFilters = true;
      this.fetchNotifications();
    },

    resetFilters() {
      this.filters = filters();
      this.hasFilters = false;
      this.fetchNotifications();
    },
  },
};
</script>
