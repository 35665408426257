import { sub } from 'date-fns';

const now = new Date();
const startDate = sub(new Date(), { days: 180 });

export default function filters() {
  return {
    startDate,
    endDate: now,
  };
}

