<template>
  <div id="faq-page">
    <!-- JUMBOTRON -->
    <div class="faq-jumbotron">
      <div class="faq-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
        <h1 class="mb-1 text-4xl font-normal text-white">
          Have Any Questions?
        </h1>
        <p class="text-white">
          Prior to contacting us, please review the frequently asked questions we receive.
          Type below to search for your question.
        </p>
        <vs-input
          v-model.lazy="query"
          placeholder="Search"
          icon-pack="feather"
          icon="icon-search"
          size="large"
          class="w-full mt-6"
          icon-no-border
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg">
        <vx-card>
          <h4>Don’t See Your Question?</h4>
          <p class="text-gray-600 mt-1">
            Open a new support ticket by clicking the button below. We will reply within one business day.
          </p>

          <vs-button
            class="mt-3"
            @click="activateZendeskWidget"
          >
            Open Support Ticket
          </vs-button>
        </vx-card>
      </div>

      <!-- FAQ COL -->
      <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
        <vs-collapse
          accordion
          type="margin"
          class="p-0"
        >
          <vs-collapse-item
            v-for="(faq, index) in faqs"
            :key="faq.id"
            :class="{'mt-0': !index}"
            class="faq-bg rounded-lg"
          >
            <template slot="header">
              <h5>{{ faq.question }}</h5>
            </template>
            <p class="whitespace-pre-line">
              {{ faq.answer }}
            </p>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RjFaqs',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      query: '',
    };
  },

  computed: {
    faqs() {
      if (this.query === '') {
        return this.items;
      }

      const query = this.query.toLowerCase();
      return this.items.filter((faq) => {
        const { question, answer } = faq;
        return question.toLowerCase().includes(query) || answer.toLowerCase().includes(query);
      });
    },
  },

  methods: {
    activateZendeskWidget() {
      window.zE(() => {
        window.zE.activate({ hideOnClose: true });
      });
    },
  },
};
</script>

<style lang="scss">
#faq-page {
  .faq-jumbotron-content {
    background-image: url('../../../assets/images/pages/faq.jpg');
    background-size: cover;
  }

  .faq-bg {
    background-color: #fff;
  }
}
</style>
